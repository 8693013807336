@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&display=swap');


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.top {
    width: 100%;
    position: relative;
    margin-bottom: 50px;
}
.top_wrapper {
    width: 100%;
}


.top_main_video {
    width: 100%;
    position: relative;
}

.top_info_main_box {
    position: absolute;
    top: 250px;
    max-width: 1024px;
    width: 100%;
    margin: 0 auto;
    left: 100px;
    z-index: 9999;

}


.top_main_title {
    color: #fff;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 54px;
    line-height: 68px;
    margin: 0;
    padding: 0;
    text-shadow: 0 -2px 2px rgba(0,0,0,.3);
}

.top_info {
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    margin-top: 8px;
    text-shadow: 0 -2px 2px rgba(0,0,0,.3);
    margin-bottom: 16px;
}

.top_main_btn {
    background: #108a00;
    border: 1px solid #108a00;
    width: 250px;
    border-radius: 6px;
    color: #ffffff;
    cursor: pointer;
    font-weight: 600;
    font-size: 18px;
    font-family: 'Open Sans', sans-serif;
    outline: none!important;
    padding: 18px 31px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
}

.top_main_links_wrapper {
    position: absolute;
    bottom: 40px;
    right: 40px;
    z-index: 999999;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.top_main_link {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    outline: none;
}

.top_main_link:first-of-type {
    margin-right: 20px;
}

#js-for-sellers-header-video {
    background-image: url("../img/header-bg.jpg");
    background-size: cover;
    height: 110%;
    left: 50%;
    position: absolute;
    top: -10%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 100%;
    z-index: 2;
}

.for-sellers__header-video {
    display: block;
    height: auto;
    min-height: 100%;
    min-width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
}


.top_main_video {
    height: 750px;
    overflow: hidden;
    position: relative;
    /*z-index: 9999;*/
}


.choose_freelance {
    width: 100%;
    margin-bottom: 48px;
}
.choose_freelance_wrapper {
    width: 100%;
    max-width: 1240px;
    margin: 0 auto;
}

.choose_freelance_title_main {
    color: #111;
    font-size: 25px;
    font-weight: 600;
    font-family: 'Open Sans', sans-serif;
    line-height: 24px;
    text-align: center;
    margin-bottom: 4px;
}

.choose_freelance_title2 {
    color: #333;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    font-weight: 400;
    font-family: 'Open Sans', sans-serif;
    margin-bottom: 42px;
}

.choose_freelance_link {
    display: flex;
    align-items: center;
    cursor: pointer;
    outline: none;
    text-decoration: none;
    color: #585858;
    width: 25%;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
    font-family: 'Open Sans', sans-serif;

}

.choose_freelance_links_wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #e8e8e8;
    border-radius: 13px;
}

.top_performers {
    background-color: #f8f8f8;
    width: 100%;
    padding-top: 48px;
    padding-bottom: 48px;
    margin-bottom: 48px;
}

.top_performers_wrapper {
    width: 100%;
    max-width: 1240px;
    margin: 0 auto;
}

.top_performers_title_main {
    color: #111;
    font-size: 25px;
    font-weight: 600;
    font-family: 'Open Sans', sans-serif;
    line-height: 24px;
    text-align: center;
    margin-bottom: 4px;
}
.top_performers_title2 {
    color: #333;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    font-weight: 400;
    font-family: 'Open Sans', sans-serif;
    margin-bottom: 42px;
}

.top_performers_items_wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.top_performers_item {
    box-shadow: 0 6px 36px rgba(0,0,0,.06);
    width: 32%!important;
    margin-bottom: 22px;
    border-radius: 17px;
    border: 1px solid #e8e8e8;
    background: #ffffff;
    padding: 15px;
}

.top_performers_item_personal_info_box {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 10px;
}
.top_performers_item_img_info_box {
    display: flex;
    align-items: flex-start;
}
.top_performers_item_img {
    width: 65px;
    height: 65px;
    margin-right: 10px;
    border-radius: 100%;
    overflow: hidden;
}

.top_performers_item_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.top_performers_item_name {
    font-family: 'Open Sans', sans-serif;
    color: #11273d;
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
}

.top_performers_item_country_name {
    font-family: 'Open Sans', sans-serif;
    color: rgba(0,0,0,.46);
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
}

.top_performers_item_portfolio_info_box_title {
    font-family: 'Open Sans', sans-serif;
    color: #11273d;
    font-size: 14px;
    margin-bottom: 10px;
    font-weight: 700;
}

.top_performers_item_portfolio_info {
    font-family: 'Open Sans', sans-serif;
    color: #11273d;
    font-size: 14px;
    margin-bottom: 20px;
    font-weight: 400;
}

.top_performers_item_portfolio_links_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.top_performers_item_portfolio_link {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    outline: none;
    text-decoration: none;
}
.top_performers_item_portfolio_link_img {
    width: 102px;
    height: 102px;
    margin-bottom: 6px;
    border-radius: 17px;
    overflow: hidden;
}

.top_performers_item_portfolio_link_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.top_performers_item_portfolio_link_title {
    font-family: 'Open Sans', sans-serif;
    color: #108a00;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
}

.top_performers_item_review_box {
    background: rgba(0,0,0,.04);
    border-radius: 15px;
    padding: 2px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.top_performers_item_review_info {
    font-family: 'Open Sans', sans-serif;
    color: #11273d;
    font-size: 11px;
    font-weight: 400;
}

.about_us {
    width: 100%;
    background-color: #ffffff;
    padding-bottom: 48px;
}
.about_us_wrapper {
    width: 100%;
    max-width: 1240px;
    margin: 0 auto;
}

.about_us_title {
    color: #111;
    font-size: 27px;
    font-weight: 600;
    line-height: 32px;
    font-family: 'Open Sans', sans-serif;
    margin-bottom: 32px;
}
.about_us_title span {
  color: #108a00;
}

.about_us_items_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.about_us_item {
    width: 48%;
}

.about_us_item_title {
    font-family: 'Open Sans', sans-serif;
    color: #111;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 15px;
}
.about_us_item_info {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    margin-top: 15px;
    color: #111;
    font-weight: 400;
    line-height: 22px;
}

.about_us_item_link {
    border: 1px solid #13544e;
    background: #13544e;
    text-decoration: none;
    cursor: pointer;
    outline: none;
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 320px;
    padding: 18px 0;
    text-align: center;
    border-radius: 6px;
    font-weight: 600;
    color: #ffffff;
    font-size: 16px;
}
