@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.main_wrapper {
    width: 100%;
}

.footer {
    width: 100%;
    border-top: 1px solid #e2e2e2;
    flex-shrink: 0;
    position: relative;
    padding-top: 48px;
    padding-bottom: 48px;
    background-color: #f6f6f6;
}

.footer_wrapper {
    width: 100%;
    max-width: 1240px;
    margin: 0 auto;
}

.footer_first_item {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 32px;
    border-bottom: 1px solid #e2e2e2;
    margin-bottom: 16px;
}

.footer_logo_link {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
    outline: none;
}

.footer_logo_link1 {
    font-size: 18px;
    color: #108a00;
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
    font-family: 'Open Sans', sans-serif;
}

.footer_logo_link2 {
    font-size: 8px;
    color: #111111;
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
    font-family: 'Open Sans', sans-serif;
}

.footer_links_items_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 80%;
}
.footer_links_item {
    margin-left: 32px;
}

.footer_links_item:first-child {
    width: 24%;
}

.footer_links_item:nth-child(2) {
    width: 17%;
}
.footer_links_item:nth-child(3) {
    width: 19%;
}
.footer_links_item_title {
    font-family: 'Open Sans', sans-serif;
    color: #111;
    font-size: 18px;
    line-height: 24px;
    position: relative;
    font-weight: 400;
}

.footer_links_item_ul_list_li {
    list-style: none;
    cursor: pointer;
    outline: none;
    padding-top: 16px;
}

.footer_links_item_ul_list_link {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    color: #333;
    display: block;
    font-size: 14px;
    line-height: 20px;
    /* margin-top: 16px; */
    text-decoration: none!important;
    -webkit-transition: color .2s;
    -o-transition: color .2s;
    transition: color .2s;
}

.footer_second_item {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.footer_google_app_store_links_wrapper {
    display: flex;
    align-items: center;
}


.footer_google_app_store_link:first-of-type {
    margin-right: 15px;
}
.footer_social_links_wrapper {
    display: flex;
    align-items: center;
}

.footer_social_link {
    display: flex;
    cursor: pointer;
    outline: none;
}
.footer_google_app_store_link {
    cursor: pointer;
    display: flex;
    outline: none;
}
