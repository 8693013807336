@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.main_wrapper {
    width: 100%;
}


.header {
    width: 100%;
    background: #ffffff;
    position: fixed;
    height: 80px;
    top: 0;
    z-index: 999999;
}

.header_wrapper {
    width: 100%;
    max-width: 1240px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
}

.header_logo_navigation_links_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 78%;
}

.header_logo_link {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
    outline: none;
}
.header_logo_link1 {
    font-size: 18px;
    color: #108a00;
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
    font-family: 'Open Sans', sans-serif;

}

.header_logo_link2 {
    font-size: 8px;
    color: #111111;
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
    font-family: 'Open Sans', sans-serif;

}

.headers_navigation_links_wrapper {
    width: 100%;
    max-width: 300px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.headers_navigation_link {
    font-size: 14px;
    font-weight: 400;
    font-family: 'Open Sans', sans-serif;
    line-height: 20px;
    /* margin: 0 8px; */
    /* padding: 8px; */
    position: relative;
    color: #11273d;
    text-decoration: none;
    cursor: pointer;
    outline: none;
}


.header_general_search_input_login_register_links_main_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header_general_search_input_icon_wrapper {
    display: flex;
    border-radius: 7px;
    border: 1px solid silver;
    height: 33px;
    width: 100%;
    max-width: 285px;
    padding-left: 10px;
    position: relative;
    overflow: hidden;
}

.header_general_search_input_field {
    border: none;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Open Sans', sans-serif;
    color: silver;

}

.header_general_search_input_icon {
    background-color: #04b70a;
    border-color: #04b70a;
    color: #fff;
    position: absolute;
    right: 0;
    width: 40px;
    height: 33px;
}

/*@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap');*/
/*@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');*/


/** {*/
/*    margin: 0;*/
/*    padding: 0;*/
/*    box-sizing: border-box;*/
/*}*/

/*.main_wrapper {*/
/*    width: 100%;*/
/*}*/


/*.header {*/
/*    width: 100%;*/
/*    background: #ffffff;*/
/*    position: fixed;*/
/*    height: 64px;*/
/*    top: 0;*/
/*}*/

/*.header_wrapper {*/
/*    width: 100%;*/
/*    max-width: 1024px;*/
/*    margin: 0 auto;*/
/*    display: flex;*/
/*    justify-content: space-between;*/
/*    align-items: center;*/
/*    padding-top: 20px;*/
/*}*/

/*.header_logo_navigation_links_wrapper {*/
/*    display: flex;*/
/*    justify-content: space-between;*/
/*    align-items: center;*/
/*    width: 45%;*/
/*}*/

/*.header_logo_link {*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    align-items: center;*/
/*    text-decoration: none;*/
/*    cursor: pointer;*/
/*    outline: none;*/
/*}*/
/*.header_logo_link1 {*/
/*    font-size: 18px;*/
/*    color: #108a00;*/
/*    text-align: center;*/
/*    font-weight: 600;*/
/*    text-transform: uppercase;*/
/*    font-family: 'Open Sans', sans-serif;*/

/*}*/

/*.header_logo_link2 {*/
/*    font-size: 8px;*/
/*    color: #111111;*/
/*    text-align: center;*/
/*    font-weight: 600;*/
/*    text-transform: uppercase;*/
/*    font-family: 'Open Sans', sans-serif;*/

/*}*/

/*.headers_navigation_links_wrapper {*/
/*    width: 100%;*/
/*    max-width: 342px;*/
/*    display: flex;*/
/*    justify-content: space-between;*/
/*    align-items: center;*/
/*}*/
/*.headers_navigation_link {*/
/*    font-size: 14px;*/
/*    font-weight: 400;*/
/*    font-family: 'Open Sans', sans-serif;*/
/*    line-height: 20px;*/
/*    !* margin: 0 8px; *!*/
/*    !* padding: 8px; *!*/
/*    position: relative;*/
/*    color: #11273d;*/
/*    text-decoration: none;*/
/*    cursor: pointer;*/
/*    outline: none;*/
/*}*/


/*.header_general_search_input_login_register_links_main_wrapper {*/
/*    display: flex;*/
/*    justify-content: space-between;*/
/*    align-items: center;*/
/*    width: 45%;*/
/*}*/

/*.header_general_search_input_icon_wrapper {*/
/*    display: flex;*/
/*    border-radius: 7px;*/
/*    border: 1px solid silver;*/
/*    height: 33px;*/
/*    width: 100%;*/
/*    max-width: 285px;*/
/*    padding-left: 20px;*/
/*    position: relative;*/
/*}*/

.header_general_search_input_field {
    border: none;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Open Sans', sans-serif;
    color: silver;

}

.header_general_search_input_icon {
    background-color: #04b70a;
    border-color: #04b70a;
    color: #fff;
    position: absolute;
    right: 0;
    width: 40px;
    height: 33px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: center;
}

.header_general_search_input_icon1 {
    width: 16px;
    border: none;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}

.header_general_search_input_icon img {
    width: 18px!important;
    height: 18px!important;
}

.header_login_link {
    color: #111;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    padding: 10px 8px;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}

.header_register_link {
    background: #04b70a;
    border: 0;
    border-radius: 4px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #fff!important;
    font-size: 14px;
    height: 40px;
    line-height: 20px;
    margin-top: -1px;
    padding: 10px 24px!important;
    text-transform: none;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
}


.header_general_search_input_icon2 {
    width: 16px;
    border: none;
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}

